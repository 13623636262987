export const privacyPolicyHtml = `
<style> 
pre {
  max-width: 100%;
  padding: 0 10% 0 10%;
  overflow-x: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px; /* İsteğe bağlı: Daha küçük yazı tipi */
}
</style>
<article> <pre>
GİZLİLİK POLİTİKASI

Son güncelleme 09 Aralık 2024

OİTO TEKNOLOJİ VE YAZILIM LİMİTED ŞİRKETİ ("biz", "bize" veya "bizim") için bu Gizlilik Bildirimi, hizmetlerimizi ("Hizmetler") kullandığınızda kişisel bilgilerinize nasıl ve neden erişebileceğimizi, bunları nasıl toplayabileceğimizi, depolayabileceğimizi, kullanabileceğimizi ve/veya paylaşabileceğimizi ("işleyebileceğimizi") açıklar. Buna şunlar dahildir:
Mobil uygulamamızı (KadınEli) veya bu Gizlilik Bildirimine bağlantı veren diğer uygulamalarımızı indirip kullandığınızda
Satış, pazarlama veya etkinlikler dahil olmak üzere diğer ilgili şekillerde bizimle etkileşim kurduğunuzda
Sorularınız veya endişeleriniz mi var? Bu Gizlilik Bildirimini okumanız gizlilik haklarınızı ve tercihlerinizi anlamanıza yardımcı olacaktır. Kişisel bilgilerinizin nasıl işleneceğine dair kararlar almaktan biz sorumluyuz. Politikalarımızı ve uygulamalarımızı kabul etmiyorsanız lütfen Hizmetlerimizi kullanmayın. Hala herhangi bir sorunuz veya endişeniz varsa lütfen tech@oito.com.tr adresinden bizimle iletişime geçin.

ÖNEMLİ NOKTALARIN ÖZETİ

Bu özet, Gizlilik Bildirimimizdeki önemli noktaları sağlar, ancak her önemli noktanın ardından gelen bağlantıya tıklayarak veya aradığınız bölümü bulmak için aşağıdaki içerik tablomuzu kullanarak bu konulardan herhangi biri hakkında daha fazla bilgi edinebilirsiniz.

Hangi kişisel bilgileri işliyoruz? Hizmetlerimizi ziyaret ettiğinizde, kullandığınızda veya gezindiğinizde, bizimle ve Hizmetlerle nasıl etkileşim kurduğunuza, yaptığınız seçimlere ve kullandığınız ürün ve özelliklere bağlı olarak kişisel bilgileri işleyebiliriz. Bize açıkladığınız kişisel bilgiler hakkında daha fazla bilgi edinin.

Hiçbir hassas kişisel bilgiyi işliyor muyuz? Bazı bilgiler, örneğin ırkınız veya etnik kökeniniz, cinsel yöneliminiz ve dini inançlarınız gibi belirli yargı bölgelerinde "özel" veya "hassas" olarak kabul edilebilir. Hassas kişisel bilgileri işlemiyoruz.

Üçüncü taraflardan herhangi bir bilgi topluyor muyuz? Üçüncü taraflardan herhangi bir bilgi toplamıyoruz.

Bilgilerinizi nasıl işliyoruz? Hizmetlerimizi sağlamak, iyileştirmek ve yönetmek, sizinle iletişim kurmak, güvenlik ve dolandırıcılığı önlemek ve yasalara uymak için bilgilerinizi işliyoruz. Bilgilerinizi ayrıca sizin izninizle başka amaçlar için de işleyebiliriz. Bilgilerinizi yalnızca geçerli bir yasal nedenimiz olduğunda işleriz. Bilgilerinizi nasıl işlediğimiz hakkında daha fazla bilgi edinin.

Kişisel bilgileri hangi durumlarda ve hangi tür taraflarla paylaşıyoruz? Bilgileri belirli durumlarda ve belirli üçüncü taraf kategorileriyle paylaşabiliriz. Kişisel bilgilerinizi ne zaman ve kiminle paylaştığımız hakkında daha fazla bilgi edinin.

Bilgilerinizi nasıl güvende tutuyoruz? Kişisel bilgilerinizi korumak için yeterli organizasyonel ve teknik süreç ve prosedürlere sahibiz. Ancak, internet üzerinden yapılan hiçbir elektronik iletim veya bilgi depolama teknolojisinin %100 güvenli olduğu garanti edilemez, bu nedenle bilgisayar korsanlarının, siber suçluların veya diğer yetkisiz üçüncü tarafların güvenliğimizi alt edemeyeceğini ve bilgilerinizi uygunsuz bir şekilde toplayamayacağını, erişemeyeceğini, çalamayacağını veya değiştiremeyeceğini vaat edemeyiz veya garanti edemeyiz. Bilgilerinizi nasıl güvende tuttuğumuz hakkında daha fazla bilgi edinin.

Haklarınız nelerdir? Coğrafi olarak nerede bulunduğunuza bağlı olarak, geçerli gizlilik yasası kişisel bilgilerinizle ilgili belirli haklara sahip olabileceğiniz anlamına gelebilir. Gizlilik haklarınız hakkında daha fazla bilgi edinin.

Haklarınızı nasıl kullanıyorsunuz? Haklarınızı kullanmanın en kolay yolu, bir veri sahibi erişim talebinde bulunmak veya bizimle iletişime geçmektir. Herhangi bir talebi geçerli veri koruma yasalarına uygun olarak değerlendireceğiz ve gereğini yapacağız.

Topladığımız herhangi bir bilgiyle ne yaptığımız hakkında daha fazla bilgi edinmek ister misiniz? Gizlilik Bildirimini eksiksiz inceleyin.

İÇİNDEKİLER

1. HANGİ BİLGİLERİ TOPLUYORUZ?
2. BİLGİLERİNİZİ NASIL İŞLİYORUZ?
3. KİŞİSEL BİLGİLERİNİZİ NE ZAMAN VE KİMİNLE PAYLAŞIYORUZ?
4. BİLGİLERİNİZİ NE KADAR SÜREYLE SAKLIYORUZ?
5. BİLGİLERİNİZİ NASIL GÜVENDE TUTUYORUZ?
6. KÜÇÜKLERDEN BİLGİ TOPLUYOR MUYUZ?
7. GİZLİLİK HAKLARINIZ NELERDİR?
8. İZLEMEME ÖZELLİKLERİ İÇİN KONTROLLER
9. BU BİLDİRİMDE GÜNCELLEMELER YAPIYOR MUYUZ?
10. BU BİLDİRİM HAKKINDA BİZE NASIL ULAŞABİLİRSİNİZ?
11. SİZDEN TOPLADIĞIMIZ VERİLERİ NASIL İNCELEYEBİLİR, GÜNCELLEYEBİLİR VEYA SİLEBİLİRSİNİZ?

1. HANGİ BİLGİLERİ TOPLUYORUZ?

Bize açıkladığınız kişisel bilgiler

Kısacası: Bize sağladığınız kişisel bilgileri topluyoruz.

Hizmetlere kaydolduğunuzda, bizim veya ürünlerimiz ve Hizmetlerimiz hakkında bilgi edinme isteğinizi belirttiğinizde, Hizmetlerdeki etkinliklere katıldığınızda veya başka bir şekilde bizimle iletişime geçtiğinizde bize gönüllü olarak sağladığınız kişisel bilgileri topluyoruz.

Sizin Tarafınızdan Sağlanan Kişisel Bilgiler. Topladığımız kişisel bilgiler, bizimle ve Hizmetlerle etkileşimlerinizin bağlamına, yaptığınız seçimlere ve kullandığınız ürünlere ve özelliklere bağlıdır. Topladığımız kişisel bilgiler şunları içerebilir:
adlar
telefon numaraları
e-posta adresleri
kullanıcı adları
şifreler
iletişim tercihleri
iletişim veya kimlik doğrulama verileri
fatura adresleri
posta adresleri
iş unvanları
Hassas Bilgiler. Hassas bilgileri işlemiyoruz.

Uygulama Verileri. Uygulamamızı/uygulamalarımızı kullanırsanız, bize erişim veya izin vermeyi seçerseniz aşağıdaki bilgileri de toplayabiliriz:
Mobil Cihaz Verileri. Cihaz bilgilerini (mobil cihaz kimliğiniz, modeliniz ve üreticiniz gibi), işletim sistemini, sürüm bilgilerini ve sistem yapılandırma bilgilerini, cihaz ve uygulama kimlik numaralarını, tarayıcı türünü ve sürümünü, donanım modelini, İnternet servis sağlayıcısını ve/veya mobil operatörünü ve İnternet Protokolü (IP) adresini (veya proxy sunucusunu) otomatik olarak toplarız. Uygulamamızı/uygulamalarımızı kullanıyorsanız, mobil cihazınızla ilişkili telefon şebekesi, mobil cihazınızın işletim sistemi veya platformu, kullandığınız mobil cihaz türü, mobil cihazınızın benzersiz cihaz kimliği ve eriştiğiniz uygulamamızın/uygulamalarımızın özellikleri hakkında bilgi de toplayabiliriz.
Anında Bildirimler. Hesabınız veya uygulamanın/uygulamaların belirli özellikleriyle ilgili olarak sizden anında bildirimler göndermemizi isteyebiliriz. Bu tür iletişimleri almaktan vazgeçmek isterseniz, bunları cihazınızın ayarlarından kapatabilirsiniz.
Bu bilgiler öncelikle uygulamamızın/uygulamalarımızın güvenliğini ve çalışmasını sağlamak, sorun gidermek ve dahili analiz ve raporlama amaçlarımız için gereklidir.

Bize sağladığınız tüm kişisel bilgiler doğru, eksiksiz ve doğru olmalı ve bu tür kişisel bilgilerde herhangi bir değişiklik olması durumunda bizi bilgilendirmelisiniz.

Otomatik olarak toplanan bilgiler

Kısaca: İnternet Protokolü (IP) adresiniz ve/veya tarayıcı ve cihaz özellikleriniz gibi bazı bilgiler, Hizmetlerimizi ziyaret ettiğinizde otomatik olarak toplanır.

Hizmetleri ziyaret ettiğinizde, kullandığınızda veya gezindiğinizde belirli bilgileri otomatik olarak toplarız. Bu bilgiler belirli kimliğinizi (adınız veya iletişim bilgileriniz gibi) ifşa etmez ancak IP adresiniz, tarayıcı ve cihaz özellikleriniz, işletim sisteminiz, dil tercihleriniz, yönlendiren URL'ler, cihaz adınız, ülkeniz, konumunuz, Hizmetlerimizi nasıl ve ne zaman kullandığınızla ilgili bilgiler ve diğer teknik bilgiler gibi cihaz ve kullanım bilgilerini içerebilir. Bu bilgiler öncelikle Hizmetlerimizin güvenliğini ve işleyişini sürdürmek ve dahili analiz ve raporlama amaçlarımız için gereklidir.

Topladığımız bilgiler şunları içerir:
Günlük ve Kullanım Verileri. Günlük ve kullanım verileri, Hizmetlerimize eriştiğinizde veya kullandığınızda sunucularımızın otomatik olarak topladığı ve günlük dosyalarına kaydettiğimiz hizmetle ilgili, tanılama, kullanım ve performans bilgileridir. Bizimle nasıl etkileşim kurduğunuza bağlı olarak, bu günlük verileri IP adresinizi, cihaz bilgilerinizi, tarayıcı türünüzü ve ayarlarınızı ve Hizmetlerdeki etkinliğinizle ilgili bilgileri (kullanımınızla ilişkili tarih/saat damgaları, görüntülenen sayfalar ve dosyalar, aramalar ve kullandığınız özellikler gibi gerçekleştirdiğiniz diğer eylemler gibi), cihaz olay bilgilerini (sistem etkinliği, hata raporları (bazen "çökme dökümleri" olarak adlandırılır) ve donanım ayarları gibi) içerebilir.
Cihaz Verileri. Hizmetlere erişmek için kullandığınız bilgisayarınız, telefonunuz, tabletiniz veya diğer cihazınızla ilgili bilgiler gibi cihaz verilerini toplarız. Kullanılan cihaza bağlı olarak, bu cihaz verileri IP adresiniz (veya proxy sunucunuz), cihaz ve uygulama tanımlama numaraları, konum, tarayıcı türü, donanım modeli, İnternet servis sağlayıcısı ve/veya mobil operatör, işletim sistemi ve sistem yapılandırma bilgileri gibi bilgileri içerebilir.
Konum Verileri. Cihazınızın konumuyla ilgili bilgiler gibi konum verilerini toplarız; bu bilgiler kesin veya kesin olmayabilir. Ne kadar bilgi topladığımız, Hizmetlere erişmek için kullandığınız cihazın türüne ve ayarlarına bağlıdır. Örneğin, bize mevcut konumunuzu (IP adresinize göre) bildiren coğrafi konum verilerini toplamak için GPS ve diğer teknolojileri kullanabiliriz. Bilgilere erişimi reddederek veya cihazınızdaki Konum ayarınızı devre dışı bırakarak bu bilgileri toplamamıza izin vermemeyi seçebilirsiniz. Ancak, vazgeçmeyi seçerseniz, Hizmetlerin belirli yönlerini kullanamayabilirsiniz.

2. BİLGİLERİNİZİ NASIL İŞLİYORUZ?

Kısaca: Hizmetlerimizi sağlamak, iyileştirmek ve yönetmek, sizinle iletişim kurmak, güvenlik ve dolandırıcılığı önlemek ve yasalara uymak için bilgilerinizi işleriz. Ayrıca izninizle bilgilerinizi başka amaçlarla da işleyebiliriz.

Hizmetlerimizle nasıl etkileşim kurduğunuza bağlı olarak kişisel bilgilerinizi çeşitli nedenlerle işleriz, bunlar şunları içerir:
Hesap oluşturma ve kimlik doğrulamayı kolaylaştırmak ve kullanıcı hesaplarını başka şekillerde yönetmek. Hesabınızı oluşturup oturum açabilmeniz ve hesabınızı çalışır durumda tutabilmeniz için bilgilerinizi işleyebiliriz.
Kullanıcıya hizmetleri sunmak ve sunulmasını kolaylaştırmak için. Talep edilen hizmeti size sağlamak için bilgilerinizi işleyebiliriz.
Size idari bilgiler göndermek için. Ürünlerimiz ve hizmetlerimiz, şartlarımızda ve politikalarımızdaki değişiklikler ve diğer benzer bilgiler hakkında ayrıntılar göndermek için bilgilerinizi işleyebiliriz.
Siparişlerinizi yerine getirmek ve yönetmek için. Hizmetler aracılığıyla yapılan siparişlerinizi, ödemelerinizi, iadelerinizi ve değişimlerinizi yerine getirmek ve yönetmek için bilgilerinizi işleyebiliriz.

Size pazarlama ve promosyon iletişimleri göndermek için. Pazarlama tercihlerinizle uyumluysa, bize gönderdiğiniz kişisel bilgileri pazarlama amaçlarımız için işleyebiliriz. Pazarlama e-postalarımızdan istediğiniz zaman çıkabilirsiniz. Daha fazla bilgi için aşağıdaki "GİZLİLİK HAKLARINIZ NELERDİR?" bölümüne bakın.
Hizmetlerimizi korumak için. Dolandırıcılık izleme ve önleme dahil olmak üzere Hizmetlerimizi güvenli ve emniyetli tutma çabalarımızın bir parçası olarak bilgilerinizi işleyebiliriz.
Yasal yükümlülüklerimize uymak için. Yasal yükümlülüklerimize uymak, yasal taleplere yanıt vermek ve yasal haklarımızı kullanmak, tesis etmek veya savunmak için bilgilerinizi işleyebiliriz.

3. KİŞİSEL BİLGİLERİNİZİ NE ZAMAN VE KİMLERLE PAYLAŞIYORUZ?

Kısaca: Bu bölümde açıklanan belirli durumlarda ve/veya aşağıdaki üçüncü taraf kategorileriyle bilgi paylaşabiliriz.

Satıcılar, Danışmanlar ve Diğer Üçüncü Taraf Hizmet Sağlayıcıları. Verilerinizi, bizim için veya bizim adımıza hizmet veren ve bu işi yapmak için bu bilgilere erişmesi gereken üçüncü taraf satıcılar, hizmet sağlayıcılar, yükleniciler veya acentelerle ("üçüncü taraflar") paylaşabiliriz.

Kişisel bilgileri paylaşabileceğimiz üçüncü taraf kategorileri şunlardır:
Kullanıcı Hesabı Kaydı ve Kimlik Doğrulama Hizmetleri
Sipariş Yerine Getirme Hizmet Sağlayıcıları

Ayrıca aşağıdaki durumlarda kişisel bilgilerinizi paylaşmamız gerekebilir:
İşletme Devirleri. Bilgilerinizi, birleşme, şirket varlıklarının satışı, finansmanı veya işimizin tamamının veya bir kısmının başka bir şirkete devredilmesiyle bağlantılı olarak veya bu tür görüşmeler sırasında paylaşabilir veya aktarabiliriz.
İş Ortakları. Bilgilerinizi, size belirli ürünler, hizmetler veya promosyonlar sunmak için iş ortaklarımızla paylaşabiliriz.

4. BİLGİLERİNİZİ NE KADAR SÜREYLE SAKLIYORUZ?

Kısaca: Aksi yasa tarafından zorunlu kılınmadığı sürece, bilgilerinizi bu Gizlilik Bildiriminde belirtilen amaçları yerine getirmek için gereken süre boyunca saklıyoruz.

Kişisel bilgilerinizi, yasa tarafından daha uzun bir saklama süresi gerekmediği veya izin verilmediği sürece (vergi, muhasebe veya diğer yasal gereklilikler gibi) yalnızca bu Gizlilik Bildiriminde belirtilen amaçlar için gereken süre boyunca saklayacağız. Bu bildirimdeki hiçbir amaç, kullanıcıların bizimle bir hesabı olduğu süreden daha uzun süre kişisel bilgilerinizi saklamamızı gerektirmeyecektir.

Kişisel bilgilerinizi işlemek için devam eden meşru bir iş ihtiyacımız olmadığında, bu bilgileri siler veya anonimleştiririz veya bu mümkün değilse (örneğin, kişisel bilgileriniz yedek arşivlerde saklandığı için), kişisel bilgilerinizi güvenli bir şekilde saklarız ve silme mümkün olana kadar herhangi bir başka işlemden izole ederiz.

5. BİLGİLERİNİZİ NASIL GÜVENDE TUTUYORUZ?

Kısaca: Kişisel bilgilerinizi, kurumsal ve teknik güvenlik önlemleri sistemi aracılığıyla korumayı amaçlıyoruz.

İşlediğimiz tüm kişisel bilgilerin güvenliğini korumak için tasarlanmış uygun ve makul teknik ve kurumsal güvenlik önlemleri uyguladık. Ancak, bilgilerinizi güvence altına almak için aldığımız önlemlere ve çabalara rağmen, İnternet üzerinden yapılan hiçbir elektronik iletim veya bilgi depolama teknolojisinin %100 güvenli olduğu garanti edilemez, bu nedenle bilgisayar korsanlarının, siber suçluların veya diğer yetkisiz üçüncü tarafların güvenliğimizi alt edemeyeceğini ve bilgilerinizi uygunsuz bir şekilde toplayamayacağını, erişemeyeceğini, çalamayacağını veya değiştiremeyeceğini vaat edemeyiz veya garanti edemeyiz. Kişisel bilgilerinizi korumak için elimizden gelenin en iyisini yapsak da, kişisel bilgilerin Hizmetlerimize ve Hizmetlerimizden iletilmesi sizin kendi riskiniz altındadır. Hizmetlere yalnızca güvenli bir ortamda erişmelisiniz.

6. KÜÇÜKLERDEN BİLGİ TOPLUYOR MUYUZ?

Kısaca: 18 yaşın altındaki çocuklardan bilerek veri toplamıyoruz veya onlara pazarlama yapmıyoruz.

18 yaşın altındaki çocuklardan bilerek veri toplamıyoruz, talep etmiyoruz veya onlara pazarlama yapmıyoruz ve bu tür kişisel bilgileri bilerek satmıyoruz. Hizmetleri kullanarak, en az 18 yaşında olduğunuzu veya böyle bir küçük çocuğun ebeveyni veya velisi olduğunuzu ve bu küçük çocuğun Hizmetleri kullanmasına izin verdiğinizi beyan edersiniz. 18 yaşından küçük kullanıcılardan kişisel bilgi toplandığını öğrenirsek, hesabı devre dışı bırakacağız ve bu verileri kayıtlarımızdan derhal silmek için makul önlemler alacağız. 18 yaşın altındaki çocuklardan toplamış olabileceğimiz herhangi bir veriden haberdar olursanız lütfen tech@oito.com.tr adresinden bizimle iletişime geçin.

7. GİZLİLİK HAKLARINIZ NELERDİR?

Kısaca: Ülkenize, eyaletinize veya ikamet ettiğiniz eyalete bağlı olarak hesabınızı istediğiniz zaman inceleyebilir, değiştirebilir veya sonlandırabilirsiniz.

Onayınızı geri çekme: Kişisel bilgilerinizi işlemek için onayınıza güveniyorsak, bu onay geçerli yasaya bağlı olarak açık ve/veya zımni onay olabilir, onayınızı istediğiniz zaman geri çekme hakkına sahipsiniz. Aşağıdaki "BU BİLDİRİM HAKKINDA BİZE NASIL ULAŞABİLİRSİNİZ?" bölümünde verilen iletişim bilgilerini kullanarak bizimle iletişime geçerek onayınızı istediğiniz zaman geri çekebilirsiniz.

Ancak, bunun geri çekilmeden önceki işlemenin yasallığını etkilemeyeceğini veya geçerli yasa izin verdiğinde, onay dışındaki yasal işleme gerekçelerine dayanarak gerçekleştirilen kişisel bilgilerinizin işlenmesini etkilemeyeceğini lütfen unutmayın.

Hesap Bilgileri

Herhangi bir zamanda hesabınızdaki bilgileri incelemek veya değiştirmek veya hesabınızı sonlandırmak isterseniz şunları yapabilirsiniz:
Sağlanan iletişim bilgilerini kullanarak bizimle iletişime geçin.
Hesabınızı sonlandırma talebiniz üzerine hesabınızı ve bilgilerinizi aktif veri tabanlarımızdan devre dışı bırakacağız veya sileceğiz. Ancak dolandırıcılığı önlemek, sorunları gidermek, soruşturmalara yardımcı olmak, yasal şartlarımızı uygulamak ve/veya geçerli yasal gerekliliklere uymak için dosyalarımızda bazı bilgileri saklayabiliriz.

Gizlilik haklarınızla ilgili sorularınız veya yorumlarınız varsa tech@oito.com.tr adresinden bize e-posta gönderebilirsiniz.

8. İZLEMEME ÖZELLİKLERİ İÇİN KONTROLLER

Çoğu web tarayıcısı ve bazı mobil işletim sistemleri ve mobil uygulamalar, çevrimiçi tarama etkinliklerinizle ilgili verilerin izlenmesini ve toplanmasını istemediğinizi belirtmek için etkinleştirebileceğiniz İzlememe ("DNT") özelliğini veya ayarını içerir. Bu aşamada, DNT sinyallerini tanımak ve uygulamak için tek tip bir teknoloji standardı kesinleştirilmemiştir. Bu nedenle, şu anda DNT tarayıcı sinyallerine veya çevrimiçi olarak izlenmeyi istemediğinizi otomatik olarak ileten herhangi bir mekanizmaya yanıt vermiyoruz. Gelecekte uymamız gereken bir çevrimiçi izleme standardı benimsenirse, bu Gizlilik Bildirimi'nin revize edilmiş bir sürümünde sizi bu uygulama hakkında bilgilendireceğiz.

9. BU BİLDİRİMDE GÜNCELLEMELER YAPIYOR MUYUZ?

Kısaca: Evet, ilgili yasalara uymak için bu bildirimi gerektiği şekilde güncelleyeceğiz.

Bu Gizlilik Bildirimi'ni zaman zaman güncelleyebiliriz. Güncellenen sürüm, bu Gizlilik Bildirimi'nin en üstünde güncellenmiş bir "Revize Edildi" tarihiyle belirtilecektir. Bu Gizlilik Bildirimi'nde önemli değişiklikler yaparsak, bu tür değişikliklerin bildirimini belirgin bir şekilde yayınlayarak veya doğrudan size bir bildirim göndererek sizi bilgilendirebiliriz. Bilgilerinizi nasıl koruduğumuz konusunda bilgi sahibi olmak için bu Gizlilik Bildirimi'ni sık sık incelemenizi öneririz.

10. BU BİLDİRİM HAKKINDA BİZE NASIL ULAŞABİLİRSİNİZ?

Bu bildirimle ilgili sorularınız veya yorumlarınız varsa tech@oito.com.tr adresinden bize e-posta gönderebilir veya aşağıdaki adresten posta yoluyla bizimle iletişime geçebilirsiniz:

OİTO TEKNOLOJİ VE YAZILIM LİMİTED ŞİRKETİ
ATATÜRK MAH. ERTUĞRUL GAZİ SK. METROPOL İSTANBUL C1 BLOK NO: 2 B İÇ KAPI NO: 376 ATAŞEHİR/ İSTANBUL
İstanbul 34000
Türkiye

11. SİZDEN TOPLADIĞIMIZ VERİLERİ NASIL İNCELEYEBİLİR, GÜNCELLEYEBİLİR VEYA SİLEBİLİRSİNİZ?

Ülkenizin geçerli yasalarına göre, sizden topladığımız kişisel bilgilere erişim, bunları nasıl işlediğimizle ilgili ayrıntılar, yanlışlıkları düzeltme veya kişisel bilgilerinizi silme hakkına sahip olabilirsiniz. Ayrıca, kişisel bilgilerinizin işlenmesine ilişkin onayınızı geri çekme hakkına da sahip olabilirsiniz. Bu haklar bazı durumlarda geçerli yasalarca sınırlandırılabilir. Kişisel bilgilerinizi incelemeyi, güncellemeyi veya silmeyi talep etmek için lütfen bir veri sahibi erişim talebini doldurun ve gönderin.
</pre></article>




`

        